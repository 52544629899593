
const rankNames = [
  'Vit',        // 0
  'Gul-vit',    // 1
  'Orange-vit', // 2
  'Grön-vit',   // 3
  'Blå-vit',    // 4
  'Brun-vit',   // 5
  'Gul',        // 6
  'Orange',     // 7
  'Grön',       // 8
  'Blå',        // 9
  'Brun',       // 10 
  '1 dan',      // 11
  '2 dan',      // 12
  '3 dan',      // 13
  '4 dan',      // 14
  '5 dan',      // 15
  '6 dan',      // 16
  '7 dan',      // 17
  '8 dan',      // 18
  '9 dan',      // 19
  '10 dan'      // 20
]


export {
  rankNames
}
