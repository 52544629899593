<template>
<b-row>
  <b-col cols="4">
    <RankImage :rank="person.rank" />
    {{ person.firstName }} {{ person.lastName }}
    <span v-if="age(person) < 18">({{ age(person) }} år)
      <span v-if="targetRank > 4 && ageAtDate(person, plannedPromotionDate) < 14">, född {{ birthdateAsString(person) }}</span>
      <span v-if="targetRank < 5 && ageAtDate(person, plannedPromotionDate) > 14">, född {{ birthdateAsString(person) }}</span>
    </span>
  </b-col>

  <b-col cols="1" class="text-center">
    <FeeImage :membership="membershipPaid(person)" :workout="workoutPaid(person)"/>
  </b-col>

  <b-col cols="1" class="text-right">
    <span align="right">{{ person.workouts }}</span>
  </b-col>
  
  <!-- Not approved -->
  <b-col cols="2" class="text-center">
    <div v-if="notApprovedInCurrent" @click.stop="$emit('unset-not-approved')">
      <font-awesome-icon :icon="['fas', 'xmark-square']" style="color:red"></font-awesome-icon>
    </div>
    <div v-else-if="notApprovedForRank">
      <font-awesome-icon :icon="['fas', 'xmark-square']"></font-awesome-icon>
    </div>
    <div v-else-if="editing && !approvedInCurrent && !approvedForRank" @click.stop="$emit('set-not-approved')">
      <font-awesome-icon :icon="['fas', 'square']"></font-awesome-icon>
    </div>
  </b-col>
  
  <!-- Approved -->
  <b-col cols="2" class="text-center">
    <div v-if="approvedInCurrent" @click.stop="$emit('unset-approved')">
      <font-awesome-icon :icon="['fas', 'check-square']" style="color:green"></font-awesome-icon>
    </div>
    <div v-else-if="approvedForRank">
      <font-awesome-icon :icon="['fas', 'check-square']"></font-awesome-icon>
    </div>
    <div v-else-if="editing && !notApprovedInCurrent && !notApprovedForRank" @click.stop="$emit('set-approved')">
      <font-awesome-icon :icon="['fas', 'square']"></font-awesome-icon>
    </div>
  </b-col>

  <b-col cols="2" @click.stop>
    <b-form-select
      :value="beltSize"
      :options="getBeltSizes(targetRank)"
      @change="beltSizeSelected(person, $event)"
      >
    </b-form-select>
  </b-col>
</b-row>
</template>

<script>
import FeeImage from '@/components/FeeImage.vue'
import RankImage from '@/components/RankImage.vue'
import { yearsBetweenDates } from '@/utils/date.js'

export default {
  props: {
    editing: Boolean,
    person: Object,
    targetRank: Number,
    plannedPromotionDate: String,
    approvedInCurrent: Boolean,
    approvedForRank: Boolean,
    notApprovedInCurrent: Boolean,
    notApprovedForRank: Boolean,
    beltSize: String
  },
  emits: {
    setApproved: null,
    unsetApproved: null,
    setNotApproved: null,
    unsetNotApproved: null,
    beltSizeSelected: null,
  },
  components: { FeeImage, RankImage },
  methods: {
    age(person) {
      return yearsBetweenDates(this.birthdate(person), new Date())
    },
    
    ageAtDate(person, date) {
      return yearsBetweenDates(this.birthdate(person), new Date(date))
    },
    
    birthdate(member) {
      const birthdate = member.birthdate ? member.birthdate : member.personalIdNumber.slice(0, 8)
      return new Date(birthdate.slice(0, 4) + "-" + birthdate.slice(4, 6) + "-" + birthdate.slice(6, 8))
    },
    birthdateAsString(member) {
      return this.dateToString(this.birthdate(member))
    },
    feeTypesPaid(person) {
      return person.fees
        ? person.fees.filter(fee => fee.period).map(fee => fee.type)
        : []
    },
    membershipPaid(person) {
      return this.feeTypesPaid(person).includes('membership')
    },
    workoutPaid(person) {
      return this.feeTypesPaid(person).filter(type => type.startsWith('workout-')).length > 0
    },
    dateToString(date) {
      return date.toISOString().slice(0, 10)
    },
    getBeltSizes(rank) {
      return rank < 6
        ? [ {text: '', value: null}, 'inget', '200', '220', '240', '260', '280', '300', '320' ] 
        : [ {text: '', value: null}, 'inget', '200', '220', '240', '260', '280', '300', '330', '350' ] 
    },
    beltSizeSelected(person, size) {
      const selection = { memberId: person.memberId, name: person.name, size: size, rank: this.targetRank }
      this.$emit('belt-size-selected', selection)
    }
  }
}
</script>
